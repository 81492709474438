import React from 'react';
import PropTypes from 'prop-types';
import './Match.styles.scss';
import Player from '../Player/Player.component';

const Match = ({ players, id, score, winnerId, showMatchId = true }) => {
    return (
        <div className="reacket-match">
            {showMatchId && <div className="reacket-match-id">{id}</div>}
            <div className="reacket-players">
                {players.map(({ name, seed, crest, ...player }, index) => (
                    <Player
                        key={player.id}
                        id={player.id}
                        name={name}
                        seed={seed}
                        crest={crest}
                        score={score[index]}
                        winner={player.id === winnerId}
                    />
                ))}
            </div>
        </div>
    );
};

Match.propTypes = {
    id: PropTypes.number.isRequired,
    players: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            seed: PropTypes.number,
        })
    ).isRequired,
    score: PropTypes.arrayOf(PropTypes.array).isRequired,
    winnerId: PropTypes.number.isRequired,
    showMatchId: PropTypes.bool,
};

export default Match;
