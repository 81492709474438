import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './Player.styles.scss';
import HighlightContext from '../../context/HighlightContext';

const Player = ({ id, name, seed, crest, score = [], winner, showSeed = false }) => {
    const { highlightedPlayer, setHighlightedPlayer } = useContext(HighlightContext);
    return (
        <div
            onMouseEnter={() => setHighlightedPlayer(id)}
            onMouseLeave={() => setHighlightedPlayer(null)}
            title={`${name} ${winner ? '(W)' : '(L)'}`}
            className={`reacket-player 
        ${winner ? 'reacket-winner' : ''} 
        ${highlightedPlayer === id ? 'reacket-highlighted' : ''}`}
        >
            {showSeed && (
                <div title="Seed" className="reacket-player-seed">
                    {seed}
                </div>
            )}
            <img className="crest" src={crest} alt="Player Crest" />
            <div className="reacket-player-name">{name}</div>
            {score.map((s, index) => (
                <div key={index} title="Score" className="reacket-player-score">
                    {s}
                </div>
            ))}
        </div>
    );
};

Player.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    seed: PropTypes.number.isRequired,
    crest: PropTypes.string.isRequired,
    score: PropTypes.array.isRequired,
    winner: PropTypes.bool.isRequired,
    showSeed: PropTypes.bool,
};

export default Player;
