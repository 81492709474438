import React, { useState } from 'react';
import './index.styles.scss';
import PropTypes from 'prop-types';
import Round from './components/Round/Round.component';
import Connector from './components/Connector/Connector.component';
import RoundHeader from './components/RoundHeader/RoundHeader.component';
import HighlightContext from './context/HighlightContext';
import convertMatchesToRounds from './util/convertMatchesToRounds';

const Reacket = ({ matches }) => {
    const [highlightedPlayer, setHighlightedPlayer] = useState(null);
    const highlightContextValue = { highlightedPlayer, setHighlightedPlayer };
    const rounds = convertMatchesToRounds(matches);
    return (
        <div className="brackets">
            <HighlightContext.Provider value={highlightContextValue}>
                {rounds.map((round, index) => {
                    const roundNumber = rounds.length - index;

                    return (
                        <div key={round.round} className="bracket">
                            {index > 0 && <Connector key={`${roundNumber}-c`} round={roundNumber} />}
                            <div className="rounds">
                                <RoundHeader key={`${round}-header`} round={round.round} totalRounds={rounds.length} />
                                <Round
                                    key={`${roundNumber}-r`}
                                    firstRound={index === rounds.length - 1}
                                    lastRound={index === 0}
                                    matches={round.matches}
                                    round={round.round}
                                />
                            </div>
                        </div>
                    );
                })}
            </HighlightContext.Provider>
        </div>
    );
};

Reacket.propTypes = {
    matches: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            round: PropTypes.number.isRequired,
            match: PropTypes.number.isRequired,
            players: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    seed: PropTypes.number.isRequired,
                })
            ),
            score: PropTypes.arrayOf(PropTypes.array.isRequired),
        })
    ).isRequired,
};

export default Reacket;
